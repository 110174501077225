import {FC} from 'react'
import { Manager } from '../../core/_models'

type Props = {
  manager: Manager
}

const ManagerDocumentNumberCell: FC<Props> = ({manager}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            {manager.document_number}
          </div>
        </div>
      }
    </>
  )
}

export {ManagerDocumentNumberCell}
