import {FC} from 'react'
import {Operator, OperatorStatus} from '../../core/_models'

type Props = {
  operator: Operator
}

const OperatorStatusCell: FC<Props> = ({operator}) => {
  let look: string = 'light-primary'
  switch (operator.status_id) {
    case OperatorStatus.active.id:
      look = 'light-success'
      break
    case OperatorStatus.inactive.id:
      look = 'light-secondary text-dark'
      break
    case OperatorStatus.blocked.id:
      look = 'light-warning text-dark'
      break
    case OperatorStatus.canceled.id:
      look = 'light-danger'
      break
  
  }

  return (
    <>
      {operator && (
        <div className={`badge badge-${look} fw-bolder`}>
          {operator.status_name.charAt(0).toUpperCase() + operator.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {OperatorStatusCell}
