import {FC} from 'react'
import {Coordinator, CoordinatorStatus} from '../../core/_models'

type Props = {
  coordinator: Coordinator
}

const CoordinatorStatusCell: FC<Props> = ({coordinator}) => {
  let look: string = 'light-primary'
  switch (coordinator.status_id) {
    case CoordinatorStatus.active.id:
      look = 'light-success'
      break
    case CoordinatorStatus.inactive.id:
      look = 'light-secondary text-dark'
      break
    case CoordinatorStatus.blocked.id:
      look = 'light-warning text-dark'
      break
    case CoordinatorStatus.canceled.id:
      look = 'light-danger'
      break
  }

  return (
    <>
      {coordinator && (
        <div className={`badge badge-${look} fw-bolder`}>
          {coordinator.status_name.charAt(0).toUpperCase() + coordinator.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {CoordinatorStatusCell}
