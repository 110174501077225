import {useState} from 'react'
import exportFromJSON from 'export-from-json'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {OperatorsListFilter} from './OperatorsListFilter'
import {Menus, isAccessGranted} from '../../../../../../../app/helpers/MenuAccessControl'
import {Operator} from '../../core/_models'
import {getAllExportXls} from '../../core/_requests'
import {useAuth} from '../../../../../auth'

type PropTypes = {
  showAddOperator?: boolean
}

const OperatorsListToolbar = ({showAddOperator = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddOperatorModal = () => {
    setItemIdForUpdate(null)
  }
  const [isExporting, setIsExporting] = useState(false);
  const auth = useAuth()

  const exportXLS = async () => {
    setIsExporting(true);
    const res = await getAllExportXls(auth.currentUser?.last_company_id);
    const data: Operator[] = (res) ? res : []
    const fileName = 'cmb-xls-operadores';
    const exportType = 'xls';
    setIsExporting(false);

    exportFromJSON({ data, fileName, exportType });
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <OperatorsListFilter />
      <button type='button' className='btn btn-light-primary me-3' onClick={exportXLS}>
        {isExporting ? (
          <span>
            Exportando...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <>
            <KTSVG path='/media/icons/duotune/general/export.svg' className='svg-icon-2' />
            Exportar Operadores
          </>
        )}
      </button>
      {/* begin::Add operator */}
      {showAddOperator && (
        <button type='button' style={{display:(isAccessGranted(Menus.operators.id).write) ? '': 'none'}} className='btn btn-primary' onClick={openAddOperatorModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Operador
        </button>
      )}
      {/* end::Add operator */}
    </div>
  )
}

export {OperatorsListToolbar}
