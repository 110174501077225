/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import moment from 'moment'
import {Graphic4Type} from '../core/_models'
import {getGraphic4} from '../core/_requests'
import {useAuth} from '../../../../app/modules/auth'
import {KTSVG} from '../../../../_metronic/helpers'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const Graphic4: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
  const {currentUser} = useAuth()

  const secondsToRefresh = 120

  const [refetchingSeconds, setRefetchingSeconds] = useState(secondsToRefresh)

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    'total_boxes-project',
    async () => await getGraphic4(currentUser!.last_company_id, {take: 0, skip: 25}),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  const logs = response as Graphic4Type[]

  useEffect(() => {
    refetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const timer = async () => {
    setTimeout(async () => {
      if (refetchingSeconds === 0) {
        await refetch()
        setRefetchingSeconds(secondsToRefresh)
        return
      }
      setRefetchingSeconds(refetchingSeconds - 1)
    }, 1000)
  }

  useEffect(() => {
    timer()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refetchingSeconds])

  return (
    <div className={`card ${className}`}>
      <div className='d-flex card-header border-0 ps-8 pt-5 ps-xxl-4 pe-xxl-4'>
        <div className='align-content-left'>
          <h3 className='card-title fw-bold text-dark'>
            Últimos 25 Logs de Eventos
          </h3>
        </div>
        <div className='d-flex align-content-right gap-2'>
          <h6>
            {!isFetching ? `atualizando em ${moment(new Date('2024-01-01 01:00:00')).subtract(-refetchingSeconds, 'seconds').format('mm:ss')}`
            : 'aguarde, atualizando...'}
          </h6>
          <KTSVG path='/media/icons/duotune/general/timer.svg' className='svg-icon-size-(12px) '/>
        </div>
      </div>
      {(logs && logs.length > 0) ? (
          <table>
            <thead>
              <tr>
                <th>Nº CMB</th>
                <th>Projeto</th>
                <th>Usuário</th>
                <th>Data/Hora</th>
                <th>Descrição do Log</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log, index) => {
                const bgGrey = (index % 2 === 0 ) && 'bg-secondary'
                return (
                  <tr key={index} className={`fs-7 ${bgGrey}`}>
                    <td>{log.cmb_number}</td>
                    <td>{log.project_name}</td>
                    <td>{log.user_name}</td>
                    <td>{moment(log.created_at).format('DD/MM/YY HH:mm:ss')}</td>
                    <td>{log.description}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
      ) : (
        <>
          <div className='d-flex flex-1 justify-content-center fs-3 fw-bold mt-4 mb-10' style={{ color: "#888888" }}>
            {isFetching ? (
              <div className="spinner-border text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : 'Sem dados'}
          </div>
        </>
      )}
    </div>
  )
}

export { Graphic4 }
