import {useEffect} from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ManagersListHeader} from './components/header/ManagersListHeader'
import {ManagersTable} from './table/ManagersTable'
import {ManagerEditModal} from './managers-edit-modal/ManagerEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {useQueryResponse} from '../managers-list/core/QueryResponseProvider'
import {on, off} from '../../../../helpers/events'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddManager?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

export const MANAGERS_REFRESH = 'MANAGERS_REFRESH'

const ManagersList = ({showAddManager = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  useEffect(() => {
    on(MANAGERS_REFRESH, refetch)
    return () => {
      off(MANAGERS_REFRESH, refetch)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <KTCard>
        <ManagersListHeader showAddManager={showAddManager} />
        <ManagersTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <ManagerEditModal />}
    </>
  )
}

const ManagersListWrapper = ({showAddManager = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <ManagersList showAddManager={showAddManager} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ManagersListWrapper}
