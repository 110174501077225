import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  Project,
  ProjectQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getProjectsFilter = (companyId: number, query: Record<string, any>): Promise<ProjectQueryResponse> => {
  return axios
    .get(`${API_URL}/project/filter/${companyId}`, {params: query})
    .then((d: AxiosResponse<ProjectQueryResponse>) => d.data)
}

const createProject = (companyId: number, project: Project): Promise<Project | undefined> => {
  return axios
    .post(`${API_URL}/project/${companyId}`, project)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

const updateMeasuringBoxes = (project: Project): Promise<Project | undefined> => {
  return axios
    .put(`${API_URL}/project/${project.id}`, project)
    .then((response: AxiosResponse<Response<Project>>) => response.data)
    .then((response: Response<Project>) => response.data)
}

const getAllExportXls = (company_id: ID): Promise<Project[]> => {
  return axios
    .get(`${API_URL}/project/${company_id}/export`)
    .then((d: AxiosResponse<Project[]>) => d.data)
}

export {
  getProjectsFilter,
  createProject,
  updateMeasuringBoxes,
  getAllExportXls
}
