import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  Operator,
  OperatorsQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getOperatorsFilter = (lastCompanyId: number | undefined, query: Record<string, any>): Promise<OperatorsQueryResponse> => {
  return axios
    .get(`${API_URL}/operators/filter/${lastCompanyId}`, {params: query})
    .then((d: AxiosResponse<OperatorsQueryResponse>) => d.data)
}

const createOperator = (operator: Operator): Promise<Operator | undefined> => {
  return axios
    .post(`${API_URL}/operators`, operator)
    .then((response: AxiosResponse<Response<Operator>>) => response.data)
    .then((response: Response<Operator>) => response.data)
}

const updateOperator = (operator: Operator): Promise<Operator | undefined> => {
  return axios
    .put(`${API_URL}/operators/${operator.id}`, operator)
    .then((response: AxiosResponse<Response<Operator>>) => response.data)
    .then((response: Response<Operator>) => response.data)
}

const getAllExportXls = (company_id: ID): Promise<Operator[]> => {
  return axios
    .get(`${API_URL}/operators/all/${company_id}/export`)
    .then((d: AxiosResponse<Operator[]>) => d.data)
}

export {
  getOperatorsFilter,
  createOperator,
  updateOperator,
  getAllExportXls
}
