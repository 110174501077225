import {FC} from 'react'
import {Company, CompanyStatus} from '../../core/_models'

type Props = {
  company: Company
}

const CompanyStatusCell: FC<Props> = ({company}) => {
  let look: string = 'light-primary'
  switch (company.status_id) {
    case CompanyStatus.active.id:
      look = 'light-success'
      break
    case CompanyStatus.inactive.id:
      look = 'light-secondary text-dark'
      break
    case CompanyStatus.canceled.id:
      look = 'light-danger'
      break
  }

  return (
    <>
      {company && (
        <div className={`badge badge-${look} fw-bolder`}>
          {company.status_name.charAt(0).toUpperCase() + company.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {CompanyStatusCell}
