// @ts-nocheck
import {Column} from 'react-table'
import {CoordinatorInfoCell} from './CoordinatorInfoCell'
import {CoordinatorStatusCell} from './CoordinatorStatusCell'
import {CoordinatorCustomHeader} from './CoordinatorCustomHeader'
import {Coordinator} from '../../core/_models'
import { CoordinatorDocumentNumberCell } from './CoordinatorDocumentNumberCell'
import { CoordinatorIdCell } from './CoordinatorIdCell'
import { CoordinatorActionsCell } from './CoordinatorActionsCell'
import { CoordinatorGenericCell } from './CoordinatorGenericCell'
import { CoordinatorDateCell } from './CoordinatorDateCell'
import {Menus, isAccessGranted} from '../../../../../../../app/helpers/MenuAccessControl'

const coordinatorsColumns: ReadonlyArray<Column<Coordinator>> = [
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='ID' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <CoordinatorIdCell coordinator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='Coordenador' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <CoordinatorInfoCell display={(isAccessGranted(Menus.coordinators.id).write) ? true : false} coordinator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='E-mail' className='min-w-90px' />
    ),
    id: 'email',
    Cell: ({...props}) => <CoordinatorGenericCell labelName={props.data[props.row.index].email} />,
  },
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='Documento' className='min-w-90px' />
    ),
    id: 'type',
    Cell: ({...props}) => <CoordinatorDocumentNumberCell coordinator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='Novo' className='min-w-90px' />
    ),
    id: 'is_new_user',
    Cell: ({...props}) => <CoordinatorGenericCell labelName={props.data[props.row.index].is_new_user ? 'Sim' : 'Não'} />,
  },
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='Status' className='min-w-20px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <CoordinatorStatusCell coordinator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='Criado Em' className='min-w-20px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <CoordinatorDateCell customDate={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <CoordinatorCustomHeader tableProps={props} title='Ações' display={(isAccessGranted(Menus.coordinators.id).write) ? true : false}  className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <CoordinatorActionsCell display={(isAccessGranted(Menus.coordinators.id).write) ? true : false}  coordinator={props.data[props.row.index]}
      />
    ),
  },
]

export {coordinatorsColumns}
