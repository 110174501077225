import {FC} from 'react'
import { Operator } from '../../core/_models'

type Props = {
  operator: Operator
}

const OperatorIdCell: FC<Props> = ({operator}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {operator.id}
        </div>
      </div>
      }
    </>
  )
}

export {OperatorIdCell}
