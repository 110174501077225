import {ID, Response} from '../../../../../../_metronic/helpers'

export const OperatorStatus = {
  'active': {id: 3, value: 'active', name: 'Ativo'},
  'blocked': {id: 4, value: 'blocked', name: 'Bloqueado'},
  'inactive': {id: 5, value: 'inactive', name: 'Inativo'},
  'canceled': {id: 17, value: 'canceled', name: 'Cancelado'},
}

export type Operator = {
  id?: ID
  name: string
  status_name: string
  status_id: number
  email: string
  document_number: string
  is_new_user: boolean
  created_at: Date | undefined
  company_id: number | undefined
  position: number
  last_company: number | undefined
  password: string
  role_id: number
}

export type Status = {
  id?: ID
  name?: string
}

export type OperatorsQueryResponse = Response<Array<Operator>>

export const initialOperator: Operator = {
  name: '',
  status_name: OperatorStatus.active.name,
  status_id: 3,
  email: '',
  document_number: '',
  is_new_user: true,
  created_at: undefined,
  company_id: undefined,
  position: 1,
  last_company: undefined,
  password: '',
  role_id: 1,
}
