// @ts-nocheck
import {Column} from 'react-table'
import {CompanyInfoCell} from './CompanyInfoCell'
import {CompanyStatusCell} from './CompanyStatusCell'
import {CompanyCustomHeader} from './CompanyCustomHeader'
import {Operator} from '../../core/_models'
import { CompanyIdCell } from './CompanyIdCell'
import { CompanyActionsCell } from './CompanyActionsCell'
import { CompanyDateCell } from './CompanyDateCell'

const operatorsColumns: ReadonlyArray<Column<Operator>> = [
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='ID' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <CompanyIdCell company={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Empresa' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <CompanyInfoCell company={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Status' className='min-w-20px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <CompanyStatusCell company={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Criado Em' className='min-w-20px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <CompanyDateCell customDate={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <CompanyActionsCell company={props.data[props.row.index]}
      />
    ),
  },
]

export {operatorsColumns}
