import axios, {AxiosResponse} from "axios"
import {
  Graphic1Type,
  Graphic2Type,
  Graphic3Type,
  Graphic5Type,
  Graphic4QueryResponse
} from "./_models"
import {ID} from "../../../../_metronic/helpers"

const API_URL = process.env.REACT_APP_API_URL

export const getGraphic1 = async (companyId: ID, start_date: string, end_date: string) => {
  return axios.get<Graphic1Type[]>(`${API_URL}/dashboard/graphic1/${companyId}`, {params: { start_date, end_date }})
  .then(response => response.data)
}

export const getGraphic2 = async (companyId: ID, start_date: string, end_date: string) => {
  return axios.get<Graphic2Type[]>(`${API_URL}/dashboard/graphic2/${companyId}`, {params: { start_date, end_date }})
  // return axios.get<Graphic2Type[]>(`${API_URL}/dashboard/graphic2/${companyId}`, {params: { start_date: today }})
  .then(response => response.data)
}

export const getGraphic3 = async (companyId: ID, start_date: string, end_date: string) => {
  return axios.get<Graphic3Type[]>(`${API_URL}/dashboard/graphic3/${companyId}`, {params: { start_date, end_date }})
  .then(response => response.data)
}

export const getGraphic4 = (companyId: ID, query: Record<string, any>): Promise<Graphic4QueryResponse> => {
  return axios.get(`${API_URL}/dashboard/graphic4/${companyId}`, {params: query})
    .then((d: AxiosResponse<Graphic4QueryResponse>) => d.data)
}

export const getGraphic5 = async (companyId: ID) => {
  return axios.get<Graphic5Type[]>(`${API_URL}/dashboard/graphic5/${companyId}`)
  .then(response => response.data)
}
