import {FC} from 'react'
import {MeasuringBox, MeasuringBoxStatus} from '../../core/_models'

type Props = {
  box: MeasuringBox
}

const MeasuringBoxStatusCell: FC<Props> = ({box}) => {
  let look: string = 'light-primary'
  switch (box.status_name) {
    case MeasuringBoxStatus.active.name:
      look = 'light-success'
      break
    case MeasuringBoxStatus.inactive.name:
      look = 'light-secondary text-dark'
      break
    case MeasuringBoxStatus.canceled.name:
      look = 'light-danger'
      break
  }

  return (
    <>
      {box && (
        <div className={`badge badge-${look} fw-bolder`}>
          {((!box.status_name) ? '' : box.status_name.charAt(0).toUpperCase()) + ((box.status_name) ? box.status_name?.slice(1) : '')}
        </div>
      )}
    </>
  )
}

export {MeasuringBoxStatusCell}
