import {FC} from 'react'
import { Operator } from '../../core/_models'

type Props = {
  operator: Operator
}

const OperatorDocumentNumberCell: FC<Props> = ({operator}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            {operator.document_number}
          </div>
        </div>
      }
    </>
  )
}

export {OperatorDocumentNumberCell}
