import {FC} from 'react'
import {Project} from '../../core/_models'

type Props = {
  project: Project
}

const ProjectIdCell: FC<Props> = ({project}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {project.id}
        </div>
      </div>
      }
    </>
  )
}

export {ProjectIdCell}
