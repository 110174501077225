import {useQuery} from 'react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getMeasuringBoxesFilter} from '../core/_requests'
import {MeasuringBoxStatus} from '../core/_models'
import {useAuth} from '../../../../auth'

const EditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {currentUser} = useAuth()

  const {
    isLoading,
    data: operator,
    error,
  } = useQuery(
    `${QUERIES.MEASURING_BOXES_LIST}-measuring-box-${itemIdForUpdate}`,
    () => {
      return getMeasuringBoxesFilter({company_id: currentUser?.last_company_id, id: itemIdForUpdate, take: 0, skip: 1})
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <EditModalForm
        isMeasuringBoxLoading={isLoading}
        response={{
          status_id: MeasuringBoxStatus.active.id,
          created_at: new Date(),
        }}
      />
    )
  }

  if (!isLoading && !error && operator) {
    return (
      <EditModalForm
      isMeasuringBoxLoading={isLoading}
      response={operator?.data}
      />
    )
  }

  return null
}

export {EditModalFormWrapper}
