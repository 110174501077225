import {useState} from 'react'
import exportFromJSON from 'export-from-json'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ManagersListFilter} from './ManagersListFilter'
import {Menus, isAccessGranted} from '../../../../../../../app/helpers/MenuAccessControl'
import {Manager} from '../../core/_models'
import {getAllExportXls} from '../../core/_requests'
import {useAuth} from '../../../../../auth'

type PropTypes = {
  showAddManager?: boolean
}

const ManagersListToolbar = ({showAddManager = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddManagerModal = () => {
    setItemIdForUpdate(null)
  }
  const [isExporting, setIsExporting] = useState(false);
  const auth = useAuth()

  const exportXLS = async () => {
    setIsExporting(true);
    const res = await getAllExportXls(auth.currentUser?.last_company_id);
    const data: Manager[] = (res) ? res : []
    const fileName = 'cmb-xls-gerentes';
    const exportType = 'xls';
    setIsExporting(false);

    exportFromJSON({ data, fileName, exportType });
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ManagersListFilter />
      <button type='button' className='btn btn-light-primary me-3' onClick={exportXLS}>
        {isExporting ? (
          <span>
            Exportando...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <>
            <KTSVG path='/media/icons/duotune/general/export.svg' className='svg-icon-2' />
            Exportar Gerentes
          </>
        )}
      </button>
      {/* begin::Add manager */}
      {showAddManager && (
        <button type='button' className='btn btn-primary' style={{display:(isAccessGranted(Menus.managers.id).write) ? '': 'none'}} onClick={openAddManagerModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Gerente
        </button>
      )}
      {/* end::Add manager */}
    </div>
  )
}

export {ManagersListToolbar}
