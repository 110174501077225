import {useState} from 'react'
import exportFromJSON from 'export-from-json'
import {KTSVG}  from '../../../../../../../_metronic/helpers'
import {ListFilter} from './ListFilter'
import {LogBox} from '../../core/_models'
import {getAllExportXls} from '../../core/_requests'
import {useAuth} from '../../../../../auth'
import {useQueryRequest} from '../../core/QueryRequestProvider'

type PropTypes = {
  showAddLogBox?: boolean
}

const ListToolbar = ({showAddLogBox = true}: PropTypes) => {
  const [isExporting, setIsExporting] = useState(false);
  const auth = useAuth()
  const {state} = useQueryRequest()

  const exportXLS = async () => {
    setIsExporting(true);
    const res = await getAllExportXls(auth.currentUser?.last_company_id, state.filter as Partial<LogBox>);
    const data: LogBox[] = (res) ? res : []
    const fileName = 'cmb-xls-logs';
    const exportType = 'xls';
    setIsExporting(false);

    exportFromJSON({ data, fileName, exportType });
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ListFilter />
      <button type='button' className='btn btn-light-primary me-3' onClick={exportXLS}>
        {isExporting ? (
          <span>
            Exportando...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <>
            <KTSVG path='/media/icons/duotune/general/export.svg' className='svg-icon-2' />
            Exportar Logs
          </>
        )}
      </button>
      {/* begin::Add log box */}
      
      {/* end::Add log box */}
    </div>
  )
}

export {ListToolbar}
