import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  Administrator,
  AdministratorsQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getAdministratorsFilter = (lastCompanyId: number | undefined, query: Record<string, any>): Promise<AdministratorsQueryResponse> => {
  return axios
    .get(`${API_URL}/administrators/filter/${lastCompanyId}`, {params: query})
    .then((d: AxiosResponse<AdministratorsQueryResponse>) => d.data)
}

const createAdministrator = (administrator: Administrator): Promise<Administrator | undefined> => {
  return axios
    .post(`${API_URL}/administrators`, administrator)
    .then((response: AxiosResponse<Response<Administrator>>) => response.data)
    .then((response: Response<Administrator>) => response.data)
}

const updateAdministrator = (administrator: Administrator): Promise<Administrator | undefined> => {
  return axios
    .put(`${API_URL}/administrators/${administrator.id}`, administrator)
    .then((response: AxiosResponse<Response<Administrator>>) => response.data)
    .then((response: Response<Administrator>) => response.data)
}

const getAllExportXls = (company_id: ID): Promise<Administrator[]> => {
  return axios
    .get(`${API_URL}/administrators/all/${company_id}/export`)
    .then((d: AxiosResponse<Administrator[]>) => d.data)
}

export {
  getAdministratorsFilter,
  createAdministrator,
  updateAdministrator,
  getAllExportXls
}
