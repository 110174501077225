import {useListView} from '../../core/ListViewProvider'
import {AdministratorsListToolbar} from './AdministratorsListToolbar'
import {AdministratorsListGrouping} from './AdministratorsListGrouping'
import {AdministratorsSearchComponent} from './AdministratorsSearchComponent'

type PropTypes = {
  showAddAdministrator?: boolean
}

const AdministratorsListHeader = ({showAddAdministrator = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <AdministratorsSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <AdministratorsListGrouping />
        ) : (
          <AdministratorsListToolbar showAddAdministrator={showAddAdministrator} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {AdministratorsListHeader}
