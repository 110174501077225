/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {updateCoordinator} from '../../core/_requests'
import {Coordinator, CoordinatorStatus} from '../../core/_models'
import {AlertModal} from '../../../../../../components/AlertModal'
import SVG from 'react-inlinesvg'
import {copyTextToClipboard} from '../../../../../../helpers/copyTextToClipboard'
import {toast} from 'react-toastify'
import {passwordRecovery} from '../../../../../auth/core/_requests'

type Props = {
  coordinator: Coordinator
  display: boolean
}

const CoordinatorActionsCell: FC<Props> = ({coordinator, display = true}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [showModalNewPassword, setShowModalNewPassword] = useState(false)
  const [showModalConfirmationNewPassword, setShowModalConfirmationNewPassword] = useState(false)
  const [novaSenhaCriada, setNovaSenhaCriada] = useState('')
  const [isCreatingNewPassword, setIsCreatingNewPassword] = useState(false)
  const [showCancellationModal, setShowCancellationModal] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(coordinator.id)
  }

  const changeStatus = useMutation(
    (newStatus: number) => updateCoordinator({id: coordinator.id, status_id: newStatus} as Coordinator),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.COORDINATORS_LIST}-${query}`])
      },
    }
  )

  const createNewPassword = () => {
    setIsCreatingNewPassword(true)
    passwordRecovery(coordinator.email).then((response) => {
      if (response.success && response.new_password) {
        setNovaSenhaCriada(response.new_password)
        toast.success('Nova senha criada com sucesso!')
      } else {
        toast.error(response.message)
      }
    }).finally(() =>
      setIsCreatingNewPassword(false)
    )

    setShowModalNewPassword(false)
    setShowModalConfirmationNewPassword(true)
  }

  const handleCreateNewPassword = () => {
    setShowModalNewPassword(!showModalNewPassword)
  }

  const confirmNewPassword = async () => {
    setShowModalConfirmationNewPassword(false)
    await copyTextToClipboard(novaSenhaCriada).then((copied) => {
      if (copied.success) toast.success('Senha copiada com sucesso para a área de transferência!')
      else toast.error('Erro ao copiar a senha para a área de transferência: ' + copied.error)
    })
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        style={{display:(!display) ? 'none' : ''}}
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Editar
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link
            to={`/apps/coordinators-management/${coordinator.id}/measuring-boxes-list`}
            state={{ coordinator }}
            className='menu-link px-3'
          >
            Caixas
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <Link
            to={`/apps/coordinators-management/${coordinator.id}/companies-list`}
            state={{ coordinator }}
            className='menu-link px-3'
          >
            Empresas
          </Link>
        </div>
        {/* end::Menu item */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Menu item */}
        {coordinator.status_id !== 3 &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='activate_row'
              onClick={async () => await changeStatus.mutateAsync(CoordinatorStatus.active.id)}
            >
              Ativar
            </a>
          </div>
        }
        {/* end::Menu item */}


        {/* begin::Menu item */}
        {coordinator.status_id !== 4 &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='inactivate_row'
              onClick={async () => await changeStatus.mutateAsync(CoordinatorStatus.blocked.id)}
            >
              Bloquear
            </a>
          </div>
        }
        {/* end::Menu item */}


        {/* begin::Menu item */}
        {coordinator.status_id !== 5 &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='inactivate_row'
              onClick={async () => await changeStatus.mutateAsync(CoordinatorStatus.inactive.id)}
            >
              Desativar
            </a>
          </div>
        }
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {coordinator.status_id !== 7 &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='canceled_row'
              onClick={() => setShowCancellationModal(true)}
            >
              Excluir
            </a>
          </div>
        }
        {/* end::Menu item */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='activate_row'
            onClick={handleCreateNewPassword}
          >
            Criar Nova Senha
          </a>
        </div>
        {/* end::Menu item */}

      </div>
      {/* end::Menu */}

      <AlertModal
        id='handleCreateNewPassword'
        show={showModalNewPassword}
        title='Confirma a criação de nova senha?'
        buttonConfirm={{ fnCallback: createNewPassword }}
        buttonCancel={{ fnCallback: () => setShowModalNewPassword(false) }}
      >
        Esta operação irá criar uma nova senha e substituirá a senha atual do coordenador {coordinator.name.trim()}.<br/><br />
        Ao final da operação, a nova senha será apresentada.
      </AlertModal>

      <AlertModal
        id='handleConfirmationCreateNewPassword'
        show={showModalConfirmationNewPassword}
        title='Nova senha criada com sucesso!'
        buttonConfirm={{ title: 'Copiar senha e fechar', fnCallback: confirmNewPassword}}
      >
        A senha abaixo foi criada para o coordenador {coordinator.name.trim()}.<br />
        Ao clicar no botão "Copiar senha e fechar", a senha gerada será copiada automaticamente para a área de transferência.<br /><br />
        <div className="d-flex justify-content-center align-items-center gap-2">
        <span className={`svg-icon symbol`}>
          <SVG src='/media/icons/duotune/general/lock1.svg' style={{scale: "1.25"}}/>
        </span>
        {isCreatingNewPassword
          ? <span className="fw-bold fs-5">Aguarde...</span>
          : <h3 className="m-0">{novaSenhaCriada}</h3>
        }
        </div>
      </AlertModal>

      <AlertModal
        id='handleCancellation'
        show={showCancellationModal}
        title='Confirmação de Exclusão'
        buttonConfirm={{ title: 'Sim', fnCallback: async () => {
          await changeStatus.mutateAsync(CoordinatorStatus.canceled.id)
          setShowCancellationModal(false)
        }}}
        buttonCancel={{ title: 'Não', fnCallback: () => setShowCancellationModal(false) }}
      >
        Confirma a Exclusão do Coordenador {coordinator.name}?
      </AlertModal>
    </>
  )
}

export {CoordinatorActionsCell}
