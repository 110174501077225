import {useState} from 'react'
import exportFromJSON from 'export-from-json'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {ListFilter} from './ListFilter'
import {Project} from '../../core/_models'
import {getAllExportXls} from '../../core/_requests'
import {useAuth} from '../../../../../auth'

type PropTypes = {
  showAddProject?: boolean
}

const ListToolbar = ({showAddProject = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddProjectModal = () => {
    setItemIdForUpdate(null)
  }
  const [isExporting, setIsExporting] = useState(false);
  const auth = useAuth()

  const exportXLS = async () => {
    setIsExporting(true);
    const res = await getAllExportXls(auth.currentUser?.last_company_id);
    const data: Project[] = (res) ? res : []
    const fileName = 'cmb-xls-projetos';
    const exportType = 'xls';
    setIsExporting(false);

    exportFromJSON({ data, fileName, exportType });
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <ListFilter />
      <button type='button' className='btn btn-light-primary me-3' onClick={exportXLS}>
        {isExporting ? (
          <span>
            Exportando...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <>
            <KTSVG path='/media/icons/duotune/general/export.svg' className='svg-icon-2' />
            Exportar Projetos
          </>
        )}
      </button>
      {/* begin::Add project */}
      {showAddProject && (
        <button type='button' className='btn btn-primary' onClick={openAddProjectModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Projeto
        </button>
      )}
      {/* end::Add project */}
    </div>
  )
}

export {ListToolbar}
