import {useMemo} from 'react'
import {useTable, ColumnInstance, Row} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {operatorsColumns} from './columns/_columns'
import {Operator} from '../core/_models'
import {OperatorsListLoading} from '../components/loading/OperatorsListLoading'
import {OperatorsListPagination} from '../components/pagination/OperatorsListPagination'
import {KTCardBody} from '../../../../../../_metronic/helpers'

type PropTypes = {
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

const OperatorsTable = ({hiddenColumns = []}: PropTypes) => {
  const operators = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => operators, [operators])
  const columns = useMemo(() => operatorsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  })
  return (
    <KTCardBody className='py-4'>
      <div className='table-responsive'>
        <table
          id='kt_table_users'
          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
          {...getTableProps()}
        >
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers
                .filter((column: ColumnInstance<Operator>) => !hiddenColumns.includes(column.id))
                .map((column: ColumnInstance<Operator>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0
              ? rows.map((row: Row<Operator>, i) => {
                  prepareRow(row)
                  return (
                    <CustomRow row={row} key={`row-${i}-${row.id}`} hiddenColumns={hiddenColumns} />
                  )
                })
              : !isLoading && (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        Nenhum registro correspondente encontrado
                      </div>
                    </td>
                  </tr>
                )}
          </tbody>
        </table>
      </div>
      <OperatorsListPagination />
      {isLoading && <OperatorsListLoading />}
    </KTCardBody>
  )
}

export {OperatorsTable}
