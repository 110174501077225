import {FC} from 'react'
import moment from 'moment';

type Props = {
  customDate: Date
}

const OperatorDateCell: FC<Props> = ({customDate}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            {customDate && moment(customDate).format('DD/MM/YY HH:mm')}
          </div>
        </div>
      }
    </>
  )
}

export {OperatorDateCell}
