import {FC} from 'react'
import {MeasuringBox} from '../../core/_models'

type Props = {
  box: MeasuringBox
}

const MeasuringBoxIdCell: FC<Props> = ({box}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {box.id}
        </div>
      </div>
      }
    </>
  )
}

export {MeasuringBoxIdCell}
