import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  LogBox,
  LogsBoxQueryResponse,
  ProjectsQueryResponse
} from './_models'
import {OperatorsQueryResponse} from '../../../operators-management/operators-list/core/_models'

const API_URL = process.env.REACT_APP_API_URL

const getLogsBoxFilter = (query: Record<string, any>): Promise<LogsBoxQueryResponse> => {
  return axios
    .get(`${API_URL}/log-box/filter`, {params: query})
    .then((d: AxiosResponse<LogsBoxQueryResponse>) => d.data)
}

const createMeasuringBoxes = (logBox: LogBox): Promise<LogBox | undefined> => {
  return axios
    .post(`${API_URL}/XXXXXXXXXX`, logBox)
    .then((response: AxiosResponse<Response<LogBox>>) => response.data)
    .then((response: Response<LogBox>) => response.data)
}

const updateMeasuringBoxes = (logBox: LogBox): Promise<LogBox | undefined> => {
  return axios
    .put(`${API_URL}/XXXXXXXXXX/${logBox.id}`, logBox)
    .then((response: AxiosResponse<Response<LogBox>>) => response.data)
    .then((response: Response<LogBox>) => response.data)
}

const getAllExportXls = (company_id: ID, filter: Partial<LogBox>): Promise<LogBox[]> => {
  return axios
    .get(`${API_URL}/log-box/${company_id}/export`, {params: filter})
    .then((d: AxiosResponse<LogBox[]>) => d.data)
}

const getAllProjecs = (company_id: number): Promise<ProjectsQueryResponse> => {
  return axios.get(`${API_URL}/project/${company_id}`).then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getAllOperators = (company_id: number, query: Record<string, string | number>): Promise<OperatorsQueryResponse> => {
  return axios
    .get(`${API_URL}/operators/filter/${company_id}`, {params: query})
    .then((d: AxiosResponse<OperatorsQueryResponse>) => d.data)
}

export {
  getLogsBoxFilter,
  createMeasuringBoxes,
  updateMeasuringBoxes,
  getAllExportXls,
  getAllProjecs,
  getAllOperators
}
