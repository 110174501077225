import {useEffect} from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {OperatorsListHeader} from './components/header/OperatorsListHeader'
import {OperatorsTable} from './table/OperatorsTable'
import {OperatorEditModal} from './operators-edit-modal/OperatorEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {useQueryResponse} from '../operators-list/core/QueryResponseProvider'
import {on, off} from '../../../../helpers/events'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddOperator?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

export const OPERATORS_REFRESH = 'OPERATORS_REFRESH'

const OperatorsList = ({showAddOperator = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  useEffect(() => {
    on(OPERATORS_REFRESH, refetch)
    return () => {
      off(OPERATORS_REFRESH, refetch)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <KTCard>
        <OperatorsListHeader showAddOperator={showAddOperator} />
        <OperatorsTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <OperatorEditModal />}
    </>
  )
}

const OperatorsListWrapper = ({showAddOperator = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <OperatorsList showAddOperator={showAddOperator} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {OperatorsListWrapper}
