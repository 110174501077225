import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {isNotEmpty} from '../../../../../../_metronic/helpers'
import {initialLogBox, LogBox} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {ListLoading} from '../components/loading/ListLoading'
import {createMeasuringBoxes, updateMeasuringBoxes} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'

type Props = {
  isLogBoxLoading: boolean
  response: any
  status: any
}

const editLogBoxSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(100, 'Maximum 50 caracteres')
    .required('Nome do histórico é obrigatório')
})

const EditModalForm: FC<Props> = ({response, isLogBoxLoading}) => {
  const logBox = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  const [logBoxForEdit] = useState<LogBox>({
    ...logBox,
    bluetooth: logBox.bluetooth || initialLogBox.bluetooth,
    status_id: logBox.status_id || initialLogBox.status_id,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: logBoxForEdit,
    validationSchema: editLogBoxSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await updateMeasuringBoxes(values)
          toast.success(`Histórico ${values.bluetooth} atualizado com sucesso`)
        } else {
          await createMeasuringBoxes(values)
          toast.success(`Histórico ${values.bluetooth} criado com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Bluetooth</label>
            <input
              placeholder='Bluetooth'
              {...formik.getFieldProps('bluetooth')}
              type='text'
              name='bluetooth'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.bluetooth && formik.errors.bluetooth},
                {
                  'is-valid': formik.touched.bluetooth && !formik.errors.bluetooth,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLogBoxLoading}
            />
            {formik.touched.bluetooth && formik.errors.bluetooth && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.bluetooth}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Número CMB</label>
            <input
              placeholder='Número CMB'
              {...formik.getFieldProps('cmb_number')}
              type='text'
              name='cmb_number'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.cmb_number && formik.errors.cmb_number},
                {
                  'is-valid': formik.touched.cmb_number && !formik.errors.cmb_number,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLogBoxLoading}
            />
            {formik.touched.cmb_number && formik.errors.cmb_number && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cmb_number}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Projeto</label>
            <input
              placeholder='Projeto'
              {...formik.getFieldProps('project_name')}
              type='text'
              name='project_name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.project_name && formik.errors.project_name},
                {
                  'is-valid': formik.touched.project_name && !formik.errors.project_name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLogBoxLoading}
            />
            {formik.touched.project_name && formik.errors.project_name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.project_name}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Endereço</label>
            <input
              placeholder='Endereço'
              {...formik.getFieldProps('address')}
              type='text'
              name='address'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.address && formik.errors.address},
                {
                  'is-valid': formik.touched.address && !formik.errors.address,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLogBoxLoading}
            />
            {formik.touched.address && formik.errors.address && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.address}</span>
                </div>
              </div>
            )}
          </div>
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Operação</label>
            <input
              placeholder='Operação'
              {...formik.getFieldProps('default_password')}
              type='text'
              name='default_password'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.description && formik.errors.description},
                {
                  'is-valid': formik.touched.description && !formik.errors.description,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLogBoxLoading}
            />
            {formik.touched.description && formik.errors.description && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.description}</span>
                </div>
              </div>
            )}
          </div>
          
          <div className='row mb-7 m-0 p-0'>
            <div className='col-sm-5 ps-0'>
              <label className='fw-bold fs-6 mb-2'>Status</label>
              <select
                className='form-control form-control-solid form-select mb-3 mb-lg-0'
                {...formik.getFieldProps('status_id')}
                name='status_id'
                disabled={formik.isSubmitting || isLogBoxLoading}
              >
                <option disabled>
                  Selecione o status
                </option>
                <option value={'6'}>Ativo</option>
                <option value={'7'}>Inativo</option>
              </select>
              {formik.touched.status_id && formik.errors.status_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert'>{formik.errors.status_id}</span>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* end::Scroll */}
              
        {/* begin::Actions */}
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLogBoxLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLogBoxLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isLogBoxLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLogBoxLoading) && <ListLoading />}
    </>
  )
}

export {EditModalForm}
