// @ts-nocheck
import {Column} from 'react-table'
import {ProjectCustomHeader} from './ProjectCustomHeader'
import {Project} from '../../core/_models'
import {ProjectIdCell} from './ProjectIdCell'
import {GenericCell} from './GenericCell'
import {ProjectActionsCell} from './ProjectActionsCell';
import {ProjectStatusCell} from './ProjectStatusCell';

const projectsColumns: ReadonlyArray<Column<Project>> = [
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='ID' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <ProjectIdCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].name} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Descrição' className='min-w-125px' />
    ),
    id: 'description',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].description} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Status' className='min-w-20px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <ProjectStatusCell project={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ProjectCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ProjectActionsCell project={props.data[props.row.index]}
      />
    ),
  }
]

export {projectsColumns}
