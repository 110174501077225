import {useListView} from '../../core/ListViewProvider'
import {ManagersListToolbar} from './ManagersListToolbar'
import {ManagersListGrouping} from './ManagersListGrouping'
import {ManagersSearchComponent} from './ManagersSearchComponent'

type PropTypes = {
  showAddManager?: boolean
}

const ManagersListHeader = ({showAddManager = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <ManagersSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <ManagersListGrouping />
        ) : (
          <ManagersListToolbar showAddManager={showAddManager} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {ManagersListHeader}
