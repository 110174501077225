import {FC} from 'react'
import { Administrator } from '../../core/_models'

type Props = {
  administrator: Administrator
}

const AdministratorDocumentNumberCell: FC<Props> = ({administrator}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            {administrator.document_number}
          </div>
        </div>
      }
    </>
  )
}

export {AdministratorDocumentNumberCell}
