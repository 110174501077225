import {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from '../../core/QueryRequestProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {useAuth} from '../../../../../auth'
import {getAllOperators, getAllProjecs} from '../../core/_requests'
import {Project, LogBoxOperations} from '../../core/_models'
import {Operator} from '../../../../operators-management/operators-list/core/_models'

const ListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [status, setStatus] = useState<string>()
  const [bluetooth, setBluetooth] = useState<string>()
  const [cmb_number, setCmbNumber] = useState<string>()
  const [project_name, setProjectName] = useState<string>()
  const [operator_id, setOperatorId] = useState<string>()
  const [startDate, setStartDate] = useState<string>()
  const [endDate, setEndDate] = useState<string>()
  const [operation, setOperation] = useState<string>()
  const {currentUser} = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
    setStatus('')
    setBluetooth('')
    setCmbNumber('')
    setProjectName('')
    setOperatorId('')
    setOperation('')
    setStartDate('')
    setEndDate('')
  }

  const filterData = () => {
    updateState({
      filter: {status_id: status, bluetooth, cmb_number, project_name, type_id: operation, operator_id, start_date: startDate, end_date: endDate},
      ...initialQueryState,
    })
  }

  const {data: projects} = useQuery(
    `${QUERIES.PROJECTS}`,
    () => {
      const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;
      return getAllProjecs(companyId);
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  const {data: operators} = useQuery(
    `${QUERIES.OPERATORS_LIST}`,
    async () => {
      const companyId = (currentUser?.last_company_id) ? +currentUser.last_company_id : 0;
      const result = await getAllOperators(companyId, {take: 0, skip: 50})
      if (!result) return []
      return result.data
    },
    {
      enabled: true,
      refetchOnWindowFocus: false,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {/* begin::Filter Button */}
      <button
        disabled={isLoading}
        type='button'
        className='btn btn-light-primary me-3'
        data-kt-menu-trigger='click'
      >
        <KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
        Filtrar
      </button>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
      <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Opções de Filtro</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Operador:</label>
            <select
              className='form-control form-control-solid form-select mb-3 mb-lg-0'
              name='operator_id'
              value={operator_id}
              onChange={(e) => setOperatorId(e.target.value)}
              defaultValue=''
            >
              <option selected value="" disabled>
                Selecione o operador
              </option>
              {operators &&
                (operators as Operator[]).map((operator) => {
                  return <option key={operator.id} value={`${operator.id}`}>{operator.name}</option>
                })}
            </select>
          </div>

          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Operação:</label>
            <select
              className='form-control form-control-solid form-select mb-3 mb-lg-0'
              name='operation'
              value={operation}
              onChange={(e) => setOperation(e.target.value)}
              defaultValue=''
            >
              <option selected value="" disabled>
                Selecione a operação
              </option>
              {Object.keys(LogBoxOperations).map(Number).map((key) => {
                return {id: key, description: LogBoxOperations[key].description}
              }).map((operation) => {
                  return <option key={operation.id} value={`${operation.id}`}>{operation.description}</option>
                })}
            </select>
          </div>

          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Período:</label>
            <input
                type='date'
                lang='pt-BR'
                name='start_date'
                className='form-control form-control-lg form-control-solid mb-lg-0'
                placeholder='Início'
                value={startDate}
                onChange={(e) => setStartDate(e.currentTarget.value)}
              />
              <label className='form-label fs-6 fw-bold col-lg-8 mt-3'>Até:</label>
              <input
                type='date'
                lang='pt-BR'
                name='end_date'
                className='form-control form-control-lg form-control-solid mb-lg-0'
                placeholder='Fim'
                value={endDate}
                onChange={(e) => setEndDate(e.currentTarget.value)}
              />
          </div>

          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Bluetooth:</label>
            <input
                type='text'
                name='bluetooth'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Bluetooth'
                value={bluetooth}
                onChange={(e) => setBluetooth(e.currentTarget.value)}
              />
          </div>

          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Número CMB:</label>
            <input
                type='text'
                name='cmb_number'
                className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                placeholder='Número CMB'
                value={cmb_number}
                onChange={(e) => setCmbNumber(e.currentTarget.value)}
              />
          </div>

          <div className='mb-6'>
            <label className='form-label fs-6 fw-bold col-lg-8'>Projeto:</label>
            <select
              className='form-select form-select-solid'
              name='project_id'
              value={project_name}
              onChange={(e) => setProjectName(e.target.value)}
              defaultValue=''
            >
              <option selected value="" disabled>
                Selecione o projeto
              </option>
              {projects &&
                (projects as Project[]).map((project) => {
                  return <option value={`${project.name}`}>{project.name}</option>
                })}
            </select>
          </div>

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={resetData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Redefinir
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={filterData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Aplicar
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export {ListFilter}
