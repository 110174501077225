import {ID, Response} from '../../../../../../_metronic/helpers'

export const CompanyStatus = {
  'active': {id: 1, value: 'active', name: 'Ativo'},
  'inactive': {id: 2, value: 'inactive', name: 'Inativo'},
  'canceled': {id: 15, value: 'canceled', name: 'Cancelado'},
}

export type Company = {
  id?: ID
  name: string
  status_name: string
  status_id: number
  created_at: Date | undefined
}

export type Status = {
  id?: ID
  name?: string
}


export type OperatorsQueryResponse = Response<Array<Company>>

export const initialOperator: Company = {
  name: '',
  status_name: CompanyStatus.active.name,
  status_id: 1,
  created_at: undefined,
}

