/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {Operator} from '../../core/_models'
import {useListView} from '../../core/ListViewProvider'

type Props = {
  operator: Operator
  display: boolean
}

const OperatorInfoCell: FC<Props> = ({operator, display = false}) => {
  const {setItemIdForUpdate} = useListView()
  const openEditModal = () => {
    setItemIdForUpdate(operator.id)
  }

  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column'>
        <a style={{display:(!display) ? 'none' : ''}}
          onClick={openEditModal}
          role='button'
          className='text-gray-800 text-hover-primary mb-1 pointer-cli'
        >
          {operator.name}
        </a>
        <span style={{display:(display) ? 'none' : ''}}
        >
          {operator.name}
        </span>
      </div>
    </div>
  )
}

export {OperatorInfoCell}
