import {FC} from 'react'
import {Manager, ManagerStatus} from '../../core/_models'

type Props = {
  manager: Manager
}

const ManagerStatusCell: FC<Props> = ({manager}) => {
  let look: string = 'light-primary'
  switch (manager.status_id) {
    case ManagerStatus.active.id:
      look = 'light-success'
      break
    case ManagerStatus.inactive.id:
      look = 'light-secondary text-dark'
      break
    case ManagerStatus.blocked.id:
      look = 'light-warning text-dark'
      break
    case ManagerStatus.canceled.id:
      look = 'light-danger'
      break
  }

  return (
    <>
      {manager && (
        <div className={`badge badge-${look} fw-bolder`}>
          {manager.status_name.charAt(0).toUpperCase() + manager.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {ManagerStatusCell}
