import {useState} from 'react'
import exportFromJSON from 'export-from-json'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {AdministratorsListFilter} from './AdministratorsListFilter'
import {Administrator} from '../../core/_models'
import {getAllExportXls} from '../../core/_requests'
import {useAuth} from '../../../../../auth'

type PropTypes = {
  showAddAdministrator?: boolean
}

const AdministratorsListToolbar = ({showAddAdministrator = true}: PropTypes) => {
  const {setItemIdForUpdate} = useListView()
  const openAddAdministratorModal = () => {
    setItemIdForUpdate(null)
  }
  const [isExporting, setIsExporting] = useState(false);
  const auth = useAuth()

  const exportXLS = async () => {
    setIsExporting(true);
    const res = await getAllExportXls(auth.currentUser?.last_company_id);
    const data: Administrator[] = (res) ? res : []
    const fileName = 'cmb-xls-administradores';
    const exportType = 'xls';
    setIsExporting(false);

    exportFromJSON({ data, fileName, exportType });
  }

  return (
    <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
      <AdministratorsListFilter />
      <button type='button' className='btn btn-light-primary me-3' onClick={exportXLS}>
        {isExporting ? (
          <span>
            Exportando...{' '}
            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
          </span>
        ) : (
          <>
            <KTSVG path='/media/icons/duotune/general/export.svg' className='svg-icon-2' />
            Exportar Administradores
          </>
        )}
      </button>
      {/* begin::Add administrator */}
      {showAddAdministrator && (
        <button type='button' className='btn btn-primary' onClick={openAddAdministratorModal}>
          <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-2' />
          Adicionar Administrador
        </button>
      )}
      {/* end::Add administrator */}
    </div>
  )
}

export {AdministratorsListToolbar}
