// @ts-nocheck
import {Column} from 'react-table'
import {ManagerInfoCell} from './ManagerInfoCell'
import {ManagerStatusCell} from './ManagerStatusCell'
import {ManagerCustomHeader} from './ManagerCustomHeader'
import {Manager} from '../../core/_models'
import {ManagerDocumentNumberCell} from './ManagerDocumentNumberCell'
import {ManagerIdCell} from './ManagerIdCell'
import {ManagerActionsCell} from './ManagerActionsCell'
import {ManagerGenericCell} from './ManagerGenericCell'
import {ManagerDateCell} from './ManagerDateCell'
import {Menus, isAccessGranted} from '../../../../../../../app/helpers/MenuAccessControl'

const managersColumns: ReadonlyArray<Column<Manager>> = [
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} title='ID' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <ManagerIdCell manager={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} title='Gerente' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <ManagerInfoCell display={(isAccessGranted(Menus.managers.id).write) ? true : false} manager={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} title='E-mail' className='min-w-90px' />
    ),
    id: 'email',
    Cell: ({...props}) => <ManagerGenericCell labelName={props.data[props.row.index].email} />,
  },
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} title='Documento' className='min-w-90px' />
    ),
    id: 'type',
    Cell: ({...props}) => <ManagerDocumentNumberCell manager={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} title='Novo' className='min-w-90px' />
    ),
    id: 'is_new_user',
    Cell: ({...props}) => <ManagerGenericCell labelName={props.data[props.row.index].is_new_user ? 'Sim' : 'Não'} />,
  },
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} title='Status' className='min-w-20px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <ManagerStatusCell manager={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} title='Criado Em' className='min-w-20px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <ManagerDateCell customDate={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <ManagerCustomHeader tableProps={props} display={(isAccessGranted(Menus.managers.id).write) ? true : false} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <ManagerActionsCell display={(isAccessGranted(Menus.managers.id).write) ? true : false} manager={props.data[props.row.index]}
      />
    ),
  },
]

export {managersColumns}
