import {useEffect} from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {AdministratorsListHeader} from './components/header/AdministratorsListHeader'
import {AdministratorsTable} from './table/AdministratorsTable'
import {AdministratorEditModal} from './administrators-edit-modal/AdministratorEditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {useQueryResponse} from '../administrators-list/core/QueryResponseProvider'
import {on, off} from '../../../../helpers/events'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddAdministrator?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

export const ADMINISTRATORS_REFRESH = 'ADMINISTRATORS_REFRESH'

const AdministratorsList = ({showAddAdministrator = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  useEffect(() => {
    on(ADMINISTRATORS_REFRESH, refetch)
    return () => {
      off(ADMINISTRATORS_REFRESH, refetch)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <KTCard>
        <AdministratorsListHeader showAddAdministrator={showAddAdministrator} />
        <AdministratorsTable hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <AdministratorEditModal />}
    </>
  )
}

const AdministratorsListWrapper = ({showAddAdministrator = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <AdministratorsList showAddAdministrator={showAddAdministrator} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {AdministratorsListWrapper}
