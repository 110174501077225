const QUERIES = {
  USERS_LIST: 'users-list',
  OPERATORS_LIST: 'operators-list',
  COORDINATORS_LIST: 'coordinators-list',
  MANAGERS_LIST: 'managers-list',
  ADMINISTRATORS_LIST: 'administrators-list',
  MEASURING_BOXES_LIST: 'measuring-boxes-list',
  LINK_MEASURING_BOXES_LIST: 'link-measuring-boxes-list',
  COMPANIES_LIST: 'companies-list',
  LOGS_BOX_LIST: 'logs_box_list',
  PROJECTS: 'projects',
  COMPANIES: 'companies',
}

export {QUERIES}
