import {useQuery} from 'react-query'
import {AdministratorEditModalForm} from './AdministratorEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getAdministratorsFilter} from '../core/_requests'
import {AdministratorStatus} from '../core/_models'
import {useAuth} from '../../../../auth/core/Auth'

const AdministratorEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {currentUser} = useAuth()

  const {
    isLoading,
    data: administrator,
    error,
    status
  } = useQuery(
    `${QUERIES.ADMINISTRATORS_LIST}-administrator-${itemIdForUpdate}`,
    () => {
      return getAdministratorsFilter(currentUser?.last_company_id, {id: itemIdForUpdate, take: 0, skip: 1})
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <AdministratorEditModalForm
        isAdministratorLoading={isLoading}
        response={{
          status_id: AdministratorStatus.active.id,
          created_at: new Date(),
        }}
        status={status}
      />
    )
  }

  if (!isLoading && !error && administrator) {
    return (
      <AdministratorEditModalForm
      isAdministratorLoading={isLoading}
        response={administrator?.data}
        status={status}
      />
    )
  }

  return null
}

export {AdministratorEditModalFormWrapper}
