import {ID, Response} from '../../../../../../_metronic/helpers'

export const MeasuringBoxStatus = {
  'active': {id: 6, value: 'active', name: 'Ativo'},
  'inactive': {id: 7, value: 'inactive', name: 'Inativo'},
  'canceled': {id: 14, value: 'canceled', name: 'Cancelado'},
}

export type MeasuringBox = {
  id?: ID
  name: string;
  address: string;
  bluetooth: string;
  cmb_number: string;
  default_password: string;
  status_name?: string;
  status_id: number;
  project_id: number;
  company_id: number;
}

export type Status = {
  id?: ID
  name?: string
}

export type Project = {
  id?: ID
  name?: string
  description: string
}

export type ProjectsQueryResponse = Response<Array<Project>>

export type MeasuringBoxesQueryResponse = Response<Array<MeasuringBox>>

export const initialMeasuringBox: MeasuringBox = {
  address: '',
  name: '',
  bluetooth: '',
  cmb_number: '',
  default_password: '',
  status_name: '',
  status_id: 6,
  project_id: 0,
  company_id: 0,
}

