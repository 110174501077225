import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  Coordinator,
  CoordinatorsQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getCoordinatorsFilter = (lastCompanyId: number | undefined, query: Record<string, any>): Promise<CoordinatorsQueryResponse> => {
  return axios
    .get(`${API_URL}/coordinators/filter/${lastCompanyId}`, {params: query})
    .then((d: AxiosResponse<CoordinatorsQueryResponse>) => d.data)
}

const createCoordinator = (coordinator: Coordinator): Promise<Coordinator | undefined> => {
  return axios
    .post(`${API_URL}/coordinators`, coordinator)
    .then((response: AxiosResponse<Response<Coordinator>>) => response.data)
    .then((response: Response<Coordinator>) => response.data)
}

const updateCoordinator = (coordinator: Coordinator): Promise<Coordinator | undefined> => {
  return axios
    .put(`${API_URL}/coordinators/${coordinator.id}`, coordinator)
    .then((response: AxiosResponse<Response<Coordinator>>) => response.data)
    .then((response: Response<Coordinator>) => response.data)
}

const getAllExportXls = (company_id: ID): Promise<Coordinator[]> => {
  return axios
    .get(`${API_URL}/coordinators/all/${company_id}/export`)
    .then((d: AxiosResponse<Coordinator[]>) => d.data)
}

export {
  getCoordinatorsFilter,
  createCoordinator,
  updateCoordinator,
  getAllExportXls
}
