import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  MeasuringBox,
  MeasuringBoxesQueryResponse,
  ProjectsQueryResponse
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getMeasuringBoxesFilter = (query: Record<string, any>): Promise<MeasuringBoxesQueryResponse> => {
  return axios
    .get(`${API_URL}/measuring-box/filter`, {params: query})
    .then((d: AxiosResponse<MeasuringBoxesQueryResponse>) => d.data)
}

const createMeasuringBoxes = (measuring_box: MeasuringBox): Promise<MeasuringBox | undefined> => {
  return axios
    .post(`${API_URL}/measuring-box`, measuring_box)
    .then((response: AxiosResponse<Response<MeasuringBox>>) => response.data)
    .then((response: Response<MeasuringBox>) => response.data)
}

const updateMeasuringBoxes = (measuring_box: MeasuringBox): Promise<MeasuringBox | undefined> => {
  return axios
    .put(`${API_URL}/measuring-box/${measuring_box.id}`, measuring_box)
    .then((response: AxiosResponse<Response<MeasuringBox>>) => response.data)
    .then((response: Response<MeasuringBox>) => response.data)
}

const getAllProjecs = (company_id: number): Promise<ProjectsQueryResponse> => {
  return axios.get(`${API_URL}/project/${company_id}`).then((d: AxiosResponse<ProjectsQueryResponse>) => d.data)
}

const getAllExportXls = (company_id: ID): Promise<MeasuringBox[]> => {
  return axios
    .get(`${API_URL}/measuring-box/${company_id}/export`)
    .then((d: AxiosResponse<MeasuringBox[]>) => d.data)
}

export {
  getMeasuringBoxesFilter,
  createMeasuringBoxes,
  updateMeasuringBoxes,
  getAllProjecs,
  getAllExportXls
}
