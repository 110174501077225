import {FC} from 'react'
import {Project, ProjectStatus} from '../../core/_models'

type Props = {
  project: Project
}

const ProjectStatusCell: FC<Props> = ({project}) => {
  let look: string = 'light-primary'
  switch (project.status_id) {
    case ProjectStatus.active.id:
      look = 'light-success'
      break
    case ProjectStatus.inactive.id:
      look = 'light-secondary text-dark'
      break
    case ProjectStatus.canceled.id:
      look = 'light-danger'
      break  
  }

  return (
    <>
      {project && (
        <div className={`badge badge-${look} fw-bolder`}>
          {project.status_name.charAt(0).toUpperCase() + project.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {ProjectStatusCell}
