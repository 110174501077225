import {useListView} from '../../core/ListViewProvider'
import {OperatorsListToolbar} from './OperatorsListToolbar'
import {OperatorsListGrouping} from './OperatorsListGrouping'
import {OperatorsSearchComponent} from './OperatorsSearchComponent'

type PropTypes = {
  showAddOperator?: boolean
}

const OperatorsListHeader = ({showAddOperator = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <OperatorsSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <OperatorsListGrouping />
        ) : (
          <OperatorsListToolbar showAddOperator={showAddOperator} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {OperatorsListHeader}
