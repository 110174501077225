import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  CompanyQueryResponse,
  UserCompaniesQueryResponse,
  Success,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getCompaniesByUserNoRoleFilter = (userId: ID): Promise<UserCompaniesQueryResponse> => {
  return axios
    .get(`${API_URL}/companies/${userId}`)
    .then((d: AxiosResponse<UserCompaniesQueryResponse>) => d.data)
}

const getCompaniesByUser = (userId: ID): Promise<CompanyQueryResponse> => {
  return axios
    .get(`${API_URL}/companies/${userId}?role_id=2`)
    .then((d: AxiosResponse<CompanyQueryResponse>) => d.data)
}


const getCompaniesFilter = (query: Record<string, any>): Promise<CompanyQueryResponse> => {
  return axios
    .get(`${API_URL}/companies/filter`, {params: query})
    .then((d: AxiosResponse<CompanyQueryResponse>) => d.data)
}

const linkCompany = (userId: ID, companies: ID[]): Promise<Success | undefined> => {
  const companiesToLink = companies.map((company) => {
    return {
      company_id: company,
      user_id: userId,
    }
  })
  return axios
    .post(`${API_URL}/coordinators/add-companies`, companiesToLink)
    .then((response: Response<Success>) => response.data)
}

const unlinkCompany = (company_id: ID, user_id: ID): Promise<Success | undefined> => {
  return axios
    .delete(`${API_URL}/coordinators/delete-companies`, {data: {company_id, user_id}})
    .then((response: AxiosResponse<Success>) => response.data)
}

export {
  getCompaniesByUser,
  getCompaniesByUserNoRoleFilter,
  getCompaniesFilter,
  linkCompany,
  unlinkCompany,
}
