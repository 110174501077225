/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { Graphic1 } from './components/Graphic1'
import { Graphic2 } from './components/Graphic2'
import { Graphic3 } from './components/Graphic3'
import { Graphic4 } from './components/Graphic4'
import { Graphic5 } from './components/Graphic5'
import { useMenuInnerContext } from '../../../_metronic/layout/components/header/header-menus/MenuInnerContext'

const DashboardPage: FC = () => {
  const {fullScreen} = useMenuInnerContext()

  return (
    <>
    {/* begin::Row */}
    <div className='row g-5 g-xl-8 mt-md-n5 mt-lg-n13 mt-xl-n16'>
      {/* begin::Col */}
      <div
        className={`${fullScreen && 'col-md-12 col-lg-12 col-xl-12'} col-xxl-${fullScreen ? '12': '5'} p-0 pe-xxl-5`} 
        style={{ display: 'flex', flexDirection: 'column', gap: 20 }}
      >
        <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end"
          style={{ minHeight: "250px" }}
        >
          <Graphic1 key='boxes-vs-interventions' className='graphic1' chartColor='danger' chartHeight='200px'/>
        </div>
        <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end" style={{ minHeight: "250px"}}>
          <Graphic2 key='interventions-username' className='graphic2' chartColor='warning-active' chartHeight='200px'/>
        </div>
        <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end" style={{ minHeight: "250px"}}>
          <Graphic3 key='total_erros-bux_number' className='graphic3' chartColor='info' chartHeight='200px'/>
        </div>

      </div>
      {/* end::Col */}

      {/* begin::Col */}
      <div className={`${fullScreen && 'col-md-12 col-lg-12 col-xl-12'} col-xxl-${fullScreen ? '12': '7'} bg-red`}>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 h-20'>
          <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end" style={{ minHeight: "400px"}}>
            <Graphic5 key='total_erros-bux_number' className='graphic5' chartColor='primary' chartHeight='350px'/>
          </div>
        </div>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10 h-80'>
          <div className="card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end pb-6" style={{ minHeight: "300px"}}>
            <Graphic4 key='log_boxes-events' className='graphic4' chartColor='secondary' chartHeight='250px'/>
          </div>
        </div>
      </div>
      {/* end::Col */}
    </div>
    {/* end::Row */}
    </>
  )
}

const DashboardWrapper: FC = () => {
  return (
    <DashboardPage />
  )
}

export { DashboardWrapper }
