import {FC} from 'react'
import { LogBox } from '../../core/_models'

type Props = {
  box: LogBox
}

const CompanyIdCell: FC<Props> = ({box}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {box.id}
        </div>
      </div>
      }
    </>
  )
}

export {CompanyIdCell}
