import {useListView} from '../../core/ListViewProvider'
import {CoordinatorsListToolbar} from './CoordinatorsListToolbar'
import {CoordinatorsListGrouping} from './CoordinatorsListGrouping'
import {CoordinatorsSearchComponent} from './CoordinatorsSearchComponent'

type PropTypes = {
  showAddCoordinator?: boolean
}

const CoordinatorsListHeader = ({showAddCoordinator = true}: PropTypes) => {
  const {selected} = useListView()
  return (
    <div className='card-header border-0 pt-6'>
      <CoordinatorsSearchComponent />
      {/* begin::Card toolbar */}
      <div className='card-toolbar'>
        {/* begin::Group actions */}
        {selected.length > 0 ? (
          <CoordinatorsListGrouping />
        ) : (
          <CoordinatorsListToolbar showAddCoordinator={showAddCoordinator} />
        )}
        {/* end::Group actions */}
      </div>
      {/* end::Card toolbar */}
    </div>
  )
}

export {CoordinatorsListHeader}
