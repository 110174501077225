import {PropsWithChildren, createContext, useContext, useState} from 'react'
import moment from 'moment'

type PageContextType = {
  dashboardStartDate: string
  setDashboardStartDate: (d: string) => void
  dashboardEndDate: string
  setDashboardEndDate: (d: string) => void
  today: string
  addFetchingGraphic: (graphicName: string, isFetching: boolean) => void
  resetFetchingList: () => void
  isFetchingList: Record<string, boolean>
  fullScreen: boolean
  setFullScreen: (fullScreen: boolean) => void
}

const MenuInnerContext = createContext<PageContextType>({} as PageContextType)

const useMenuInnerContext = () => useContext(MenuInnerContext)

const MenuInnerContextProvider = ({children}: PropsWithChildren<{}>) => {
  const today = moment(new Date()).format('YYYY-MM-DD')
  const [dashboardStartDate, setDashboardStartDate] = useState(today)
  const [dashboardEndDate, setDashboardEndDate] = useState(today)
  const [isFetchingList, setIsFetchingList] = useState<Record<string, boolean>>({})
  const [fullScreen, setFullScreen] = useState(false)

  return (
    <MenuInnerContext.Provider value={{
      dashboardStartDate,
      dashboardEndDate,
      setDashboardStartDate,
      setDashboardEndDate,
      today,
      addFetchingGraphic: (graphicName, isFetching) => {
        setIsFetchingList((prevGraphics) => {
          return {...prevGraphics, [graphicName]: isFetching}
        })
      },
      resetFetchingList: () => setIsFetchingList({}),
      isFetchingList,
      fullScreen,
      setFullScreen,
    }}>
      {children}
    </MenuInnerContext.Provider>
  )
}

export {useMenuInnerContext, MenuInnerContextProvider}
