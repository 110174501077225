import {FC} from 'react'
import {Administrator, AdministratorStatus} from '../../core/_models'

type Props = {
  administrator: Administrator
}

const AdministratorStatusCell: FC<Props> = ({administrator}) => {
  let look: string = 'light-primary'
  switch (administrator.status_id) {
    case AdministratorStatus.active.id:
      look = 'light-success'
      break
    case AdministratorStatus.inactive.id:
      look = 'light-secondary text-dark'
      break
    case AdministratorStatus.blocked.id:
      look = 'light-warning text-dark'
      break
    case AdministratorStatus.canceled.id:
      look = 'light-danger'
      break  
  }

  return (
    <>
      {administrator && (
        <div className={`badge badge-${look} fw-bolder`}>
          {administrator.status_name.charAt(0).toUpperCase() + administrator.status_name.slice(1)}
        </div>
      )}
    </>
  )
}

export {AdministratorStatusCell}
