import axios, {AxiosResponse} from 'axios'
import { UserCompaniesQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getUserCompanies = (currentUser: number): Promise<UserCompaniesQueryResponse> => {
  return axios
    .get(`${API_URL}/companies/${currentUser}`)
    .then((d: AxiosResponse<UserCompaniesQueryResponse>) => d.data)
}

export {
  getUserCompanies,
}
