import { ID } from '../../_metronic/helpers'
import {useAuth} from '../../app/modules/auth'

export const Menus = {
  'dashboard': {id: 10, name: 'Dashboard'},
  'companies': {id: 11, name: 'Empresas'},
  'measuring_boxes': {id: 12, name: 'Caixas'},
  'projects': {id: 13, name: 'Projetos'},
  'operators': {id: 14, name: 'Operadores'},
  'coordinators': {id: 15, name: 'Coordenadores'},
  'managers': {id: 16, name: 'Gerentes'},
  'administrators': {id: 17, name: 'Administradores'},
  'logs': {id: 18, name: 'Históricos'},
}

type MenuAccessType = {
  read: 0 | 1
  write: 0 | 1
}

const MenuAccessControl = (menuId: ID):MenuAccessType => {
  const {auth} = useAuth()

  // Check permission to access the menu
  const isAccessGranted = () => {
    if (!menuId || !auth?.menu_roles.length) return {read: 0, write: 0} as MenuAccessType

    const menu = auth.menu_roles.find((menu) => menu.menu_id === menuId)

    return {
      read: menu?.read || 0,
      write: menu?.write || 0,
    }
  }

  return isAccessGranted() as MenuAccessType
}

export {MenuAccessControl as isAccessGranted}