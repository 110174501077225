/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef, useState} from 'react'
import {useQuery} from 'react-query'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTUtil} from '../../../../_metronic/assets/ts'
import {getCSSVariableValue} from '../../../../_metronic/assets/ts/_utils'
import {Graphic5Type} from '../core/_models'
import {getGraphic5} from '../core/_requests'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
}

const Graphic5: React.FC<Props> = ({ className, chartColor, chartHeight }) => {
  const {currentUser} = useAuth()
  const chartRef = useRef<HTMLDivElement | null>(null)
  const [refreshGraphic, setRefreshGraphic] = useState(false)

  const query = useQuery(
    'log_boxes-events',
    () => getGraphic5(currentUser!.last_company_id), {
    cacheTime: 0,
    refetchOnWindowFocus: false,
  })

  const refreshChart = () => {
    if (!chartRef.current || query.isFetching) {
      return
    }

    const chart1 = new ApexCharts(chartRef.current, chart1Options(chartColor, chartHeight, query.data))
    if (chart1) {
      chart1.render()
    }

    setRefreshGraphic(false)
    return chart1
  }

  useEffect(() => {
    query.refetch().then(() => {
      setRefreshGraphic(true)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  useEffect(() => {
    const chart1 = refreshChart()

    return () => {
      if (chart1) {
        chart1.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, query.data, refreshGraphic])

  return (
    <div className={`card ${className}`}>
      <div className='card-header border-0 ps-8 ps-xxl-4'>
        <h3 className='card-title fw-bold text-dark'>
          Total de Caixas por Projeto
        </h3>
      </div>
      {(query.data && query.data.length > 0) ? (
        <div ref={chartRef} ></div>
      ) : (
        <>
          <div className='d-flex flex-1 justify-content-center fs-3 fw-bold mt-4 mb-10' style={{ color: "#888888" }}>
            {query.isFetching ? (
              <div className="spinner-border text-secondary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : 'Sem dados'}
          </div>
        </>
      )}
    </div>
  )
}

const chart1Options = (chartColor: string, chartHeight: string, data: Graphic5Type[] | undefined): ApexOptions => {
  const labelColor = getCSSVariableValue('--kt-gray-800')
  const baseColor = getCSSVariableValue('--kt-' + chartColor) as string
  const borderColor = KTUtil.getCSSVariableValue('--kt-gray-200');
  const secondaryColor = KTUtil.getCSSVariableValue('--kt-gray-300');

  return {
    series: [{
      name: 'Total de caixas do projeto',
      data: data ? data.map((box) => box.total_boxes) : []
    }],
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: chartHeight,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '75',
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    xaxis: {
      categories: data ? data.sort((a, b) =>  b.total_boxes - a.total_boxes).map((box) => box.project_name) : [],
      title: {
        text: 'Nome de Projeto',
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '10px'
        }
      }
    },
    yaxis: {
      title: {
        text: 'Total de Caixas',
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px'
        }
      }
    },
    fill: {
      opacity: 1
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      style: {
        fontSize: '12px'
      },
      x: {
        formatter: function (val) {
          return 'Projeto: ' + val
        }
      },
      y: {
        formatter: function (val) {
          return val.toString()
        }
      }
    },
    colors: [baseColor, secondaryColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true
        }
      },
    }
  }
}

export { Graphic5 }
