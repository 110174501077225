import {ID, Response} from '../../../../../../_metronic/helpers'

export const LogBoxStatus = {
  'active': {id: 8, value: 'active', name: 'Ativo'},
  'inactive': {id: 9, value: 'inactive', name: 'Inativo'},
}

export const LogBoxOperations: { [key: number]: {description: string} } = {
  1:	{description: 'Abertura da Caixa com sucesso.'},
  2:	{description: 'Fechamento executado em abertura com Contingência.'},
  3:	{description: 'Fechamento da Caixa com sucesso.'},
  4:	{description: 'Fechamento Manual da Caixa realizado.'},
  5:	{description: 'Falha na abertura de Caixa, Senha incorreta.'},
  6:	{description: 'Fechamento eletrônico executado sem abertura da Caixa.'},
  7:	{description: 'Fechamento Manual da Caixa realizado com sucesso.'},
  8:	{description: 'Fechamento Manual da Caixa confirmado com sucesso.'},
  9:	{description: 'ID da fechadura não reconhecido.'},
  10:	{description: 'Fechamento executado sem abertura da Caixa (Timeout).'},
  11:	{description: 'Erro no sensor da fechadura.'},
}

export type LogBox = {
  id?: ID
  user_id: number,
  user_name: string,
  status_id: number,
  status_name: string,
  description: string,
  measuring_box_id: number,
  project_id?: number;
  project_name: string;
  bluetooth: string;
  address: string;
  cmb_number: number;
  provider_id?: number;
  provider_name: string;
  created_at: Date;
}

export type Status = {
  id?: ID
  name?: string
}

export type Project = {
  id?: ID
  name?: string
  description: string
}

export type ProjectsQueryResponse = Response<Array<Project>>

export type LogsBoxQueryResponse = Response<Array<LogBox>>

export const initialLogBox: LogBox = {
  address: '',
  bluetooth: '',
  cmb_number: 0,
  project_name: '',
  status_name: 'Ativo',
  status_id: 6,
  created_at: new Date(),
  description: '',
  measuring_box_id: 0,
  provider_name: '',
  user_id: 0,
  user_name: '',
  project_id: 0,
  provider_id: 0
}
