import { ReactNode } from "react"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap"
import SVG from 'react-inlinesvg'

type PropTypes = {
  show: boolean
  id: string
  title: string
  buttonCancel?: {
    title?: string
    fnCallback?: () => void
  }
  buttonConfirm: {
    title?: string
    fnCallback: () => void
  }
  children: ReactNode | string | number | null
}

export const AlertModal = (props: PropTypes) => {
  return (
    <Modal
      id={props.id.replace('#','')}
      show={props.show || false}
      role='dialog'
      data-backdrop='false'
      aria-hidden='true'
      tabIndex={-1}
      centered={true}
    >
      <ModalHeader>
        <h5 className="modal-title">{props.title}</h5>
        {props.buttonCancel?.fnCallback &&
          <div style={{cursor: 'pointer'}} onClick={props.buttonCancel?.fnCallback}>
            <span className={`svg-icon`}>
              <SVG src='/media/icons/duotune/arrows/arr061.svg' />
            </span>
          </div>
        }
      </ModalHeader>
      <ModalBody>
        <p>{props.children}</p>
      </ModalBody>
      <ModalFooter>
        {props.buttonCancel?.fnCallback &&
          <button
            type="button"
            className="btn btn-light"
            onClick={props.buttonCancel?.fnCallback}
          >
            {props.buttonCancel?.title || 'Cancelar'}
          </button>
        }
        <button type="button" className="btn btn-primary"
          onClick={props.buttonConfirm?.fnCallback}
        >
          {props.buttonConfirm?.title || 'Confirmar'}
        </button>
      </ModalFooter>
    </Modal>
  )
}
