// @ts-nocheck
import {Column} from 'react-table'
import {CompanyCustomHeader} from './CompanyCustomHeader'
import {LogBox} from '../../core/_models'
import {CompanyIdCell} from './CompanyIdCell'
import moment from 'moment';
import {GenericCell} from './GenericCell'

const logsBoxColumns: ReadonlyArray<Column<LogBox>> = [
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='ID' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <CompanyIdCell box={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Operação' className='min-w-125px' />
    ),
    id: 'description',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].description} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Data' className='min-w-125px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <GenericCell labelName={moment(props.data[props.row.index].created_at).format('DD/MM/YY HH:mm')} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Operador' className='min-w-125px' />
    ),
    id: 'user_name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].user_name} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Bluetooth' className='min-w-125px' />
    ),
    id: 'bluetooth',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].bluetooth} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Endereço' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].address} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Número CMB' className='min-w-125px' />
    ),
    id: 'cmb_number',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].cmb_number} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Projeto' className='min-w-125px' />
    ),
    id: 'project_name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].project_name} />,
  },
  {
    Header: (props) => (
      <CompanyCustomHeader tableProps={props} title='Fornecedor' className='min-w-125px' />
    ),
    id: 'provider_name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].provider_name} />,
  }
]

export {logsBoxColumns}
