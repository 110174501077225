import {FC} from 'react'
import { Administrator } from '../../core/_models'

type Props = {
  administrator: Administrator
}

const AdministratorIdCell: FC<Props> = ({administrator}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
        <div className='d-flex flex-column'>
            {administrator.id}
        </div>
      </div>
      }
    </>
  )
}

export {AdministratorIdCell}
