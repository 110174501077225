// @ts-nocheck
import {Column} from 'react-table'
import {MeasuringBoxStatusCell} from './MeasuringBoxStatusCell'
import {MeasuringBoxCustomHeader} from './MeasuringBoxCustomHeader'
import {MeasuringBox} from '../../core/_models'
import {MeasuringBoxIdCell} from './MeasuringBoxIdCell'
import {MeasuringBoxActionsCell} from './MeasuringBoxActionsCell'
import {GenericCell} from './GenericCell'

const measuringBoxesColumns: ReadonlyArray<Column<MeasuringBox>> = [
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='ID' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <MeasuringBoxIdCell box={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='Bluetooth' className='min-w-100px' />
    ),
    id: 'bluetooth',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].bluetooth} />,
  },
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='Endereço' className='min-w-125px' />
    ),
    id: 'address',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].address} />,
  },
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='Número CMB' className='min-w-125px' />
    ),
    id: 'cmb_number',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].cmb_number} />,
  },
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='Senha' className='min-w-125px' />
    ),
    id: 'default_password',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].default_password} />,
  },
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='Projeto' className='min-w-125px' />
    ),
    id: 'project_name',
    Cell: ({...props}) => <GenericCell labelName={props.data[props.row.index].project_name} />,
  },
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='Status' className='min-w-100px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <MeasuringBoxStatusCell box={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <MeasuringBoxCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <MeasuringBoxActionsCell box={props.data[props.row.index]}
      />
    ),
  },
]

export {measuringBoxesColumns}
