/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {useMutation, useQueryClient} from 'react-query'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTSVG, QUERIES} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {useQueryResponse} from '../../core/QueryResponseProvider'
import {updateCompany} from '../../core/_requests'
import {Company, CompanyStatus} from '../../core/_models'
import {AlertModal} from '../../../../../../components/AlertModal'

type Props = {
  company: Company
}

const CompanyActionsCell: FC<Props> = ({company}) => {
  const {setItemIdForUpdate} = useListView()
  const {query} = useQueryResponse()
  const queryClient = useQueryClient()
  const [showCancellationModal, setShowCancellationModal] = useState(false)

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(company.id)
  }

  const changeStatus = useMutation(
    (newStatus: number) => updateCompany({id: company.id, status_id: newStatus} as Company),
    {
      // 💡 response of the mutation is passed to onSuccess
      onSuccess: () => {
        // ✅ update detail view directly
        queryClient.invalidateQueries([`${QUERIES.OPERATORS_LIST}-${query}`])
      },
    }
  )

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Editar
          </a>
        </div>
        {/* end::Menu item */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* begin::Menu item */}
        {company.status_id !== 1 &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='activate_row'
              onClick={async () => await changeStatus.mutateAsync(CompanyStatus.active.id)}
            >
              Ativar
            </a>
          </div>
        }
        {/* end::Menu item */}

        {/* begin::Menu item */}
        {company.status_id !== 2 &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='inactivate_row'
              onClick={async () => await changeStatus.mutateAsync(CompanyStatus.inactive.id)}
            >
              Desativar
            </a>
          </div>
        }

        {/* begin::Menu item */}
        {company.status_id !== 7 &&
          <div className='menu-item px-3'>
            <a
              className='menu-link px-3'
              data-kt-users-table-filter='canceled_row'
              onClick={() => setShowCancellationModal(true)}
            >
              Excluir
            </a>
          </div>
        }
        {/* end::Menu item */}

      </div>
      <AlertModal
        id='handleCancellation'
        show={showCancellationModal}
        title='Confirmação de Exclusão'
        buttonConfirm={{ title: 'Sim', fnCallback: async () => {
          await changeStatus.mutateAsync(CompanyStatus.canceled.id)
          setShowCancellationModal(false)
        }}}
        buttonCancel={{ title: 'Não', fnCallback: () => setShowCancellationModal(false) }}
      >
        Confirma a Exclusão da Empresa {company.name}?
      </AlertModal>
    </>
  )
}

export {CompanyActionsCell}
