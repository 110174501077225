import {FC} from 'react'
import {Link} from 'react-router-dom'

const PrivacyPolicy: FC = () => {
  return (
    <>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Política de Privacidade</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>
      Esta Política de Privacidade descreve como o nosso site de gestão de Bluetooth o site coleta, utiliza e protege as informações pessoais que você fornece enquanto utiliza nosso site e os serviços relacionados a ele.
Informações que Coletamos
Quando você visita nosso site, podemos coletar informações que podem ser usadas para identificá-lo ("Informações Pessoais"). As informações pessoais podem incluir, mas não estão limitadas a:
- Nome e sobrenome;
- Endereço de e-mail;
- Número de telefone;
- Endereço IP (Internet Protocol); - Informações do navegador;
- Preferências de comunicação.
Como Usamos Suas Informações
As informações pessoais coletadas são usadas para fornecer e melhorar o serviço do nosso site. Podemos usar suas informações pessoais para:
- Personalizar sua experiência e responder às suas necessidades individuais;
- Fornecer suporte ao cliente;
- Comunicar-nos com você sobre o seu uso do nosso site;
- Enviar-lhe atualizações sobre nossos serviços e outras informações que acreditamos ser do seu interesse;
- Melhorar nosso site e serviços;
- Proteger a segurança e a integridade do nosso site.
Como Protegemos Suas Informações
Valorizamos a segurança das suas informações pessoais e implementamos medidas de segurança para protegê-las contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, observe que nenhuma transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro e confiável, e não podemos garantir sua segurança absoluta.
Compartilhamento de Informações
Não vendemos, trocamos ou transferimos suas informações pessoais para terceiros sem o seu consentimento explícito, exceto conforme descrito nesta Política de Privacidade ou quando exigido por lei.
Cookies
Nosso site utiliza cookies para melhorar sua experiência de usuário. Você pode optar por configurar seu navegador para recusar todos os cookies ou para indicar quando um cookie está sendo enviado. Se você optar por não aceitar cookies, algumas partes do nosso site podem não funcionar corretamente.
Consentimento
Ao utilizar nosso site, você consente com a nossa Política de Privacidade e concorda em fornecer as informações pessoais mencionadas.
Alterações nesta Política de Privacidade
Reservamo-nos o direito de atualizar ou modificar esta Política de Privacidade a qualquer momento, conforme necessário. As alterações entrarão em vigor imediatamente após serem publicadas nesta página.
Contato
Se você tiver alguma dúvida sobre esta Política de Privacidade, entre em contato conosco através dos seguintes meios:
E-mail: chavecmbsolucoes@gmail.com Última atualização: 25/04/2024
Ao continuar usando nosso site, você confirma que leu e concorda com esta Política de Privacidade.
      </div>
      {/* end::Text */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to='/dashboard' className='btn btn-sm btn-primary'>
          Voltar para a Home
        </Link>
      </div>
      {/* end::Link */}
    </>
  )
}

export {PrivacyPolicy}
