import {useEffect} from 'react'
import {ListViewProvider, useListView} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {ListHeader} from './components/header/ListHeader'
import {Table} from './table/Table'
import {EditModal} from './logs-box-edit-modal/EditModal'
import {KTCard} from '../../../../../_metronic/helpers'
import {useQueryResponse} from './core/QueryResponseProvider'
import {on, off} from '../../../../helpers/events'

type PropTypes = {
  /** Shows the [Add User] button inside the header on the top right corner */
  showAddLogBox?: boolean
  /** An array of columns that you don't want shown in the user list */
  hiddenColumns?: string[]
}

export const LOGS_BOX_REFRESH = 'LOGS_BOX_REFRESH'

const List = ({showAddLogBox = true, hiddenColumns = []}: PropTypes) => {
  const {itemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()

  useEffect(() => {
    on(LOGS_BOX_REFRESH, refetch)
    return () => {
      off(LOGS_BOX_REFRESH, refetch)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <KTCard>
        <ListHeader showAddLogBox={showAddLogBox} />
        <Table hiddenColumns={hiddenColumns} />
      </KTCard>
      {itemIdForUpdate !== undefined && <EditModal />}
    </>
  )
}

const CompaniesListWrapper = ({showAddLogBox = true, hiddenColumns = []}: PropTypes) => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <List showAddLogBox={showAddLogBox} hiddenColumns={hiddenColumns} />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {CompaniesListWrapper}
