import {ID, Response} from '../../../../../../_metronic/helpers'

export const ProjectStatus = {
  'active': {id: 12, value: 'active', name: 'Ativo'},
  'inactive': {id: 13, value: 'inactive', name: 'Inativo'},
  'canceled': {id: 16, value: 'canceled', name: 'Cancelado'},
}

export type Project = {
  id?: ID
  name: string;
  description: string;
  status_id: number;
  status_name: string;
}

export type Status = {
  id?: ID
  name?: string
}


export type ProjectQueryResponse = Response<Array<Project>>

export const initialProject: Project = {
  name: '',
  description: '',
  status_name: 'Ativo',
  status_id: 12
}

