import {FC} from 'react'
import { Coordinator } from '../../core/_models'

type Props = {
  coordinator: Coordinator
}

const CoordinatorDocumentNumberCell: FC<Props> = ({coordinator}) => {
  return (
    <>
      {
        <div className='d-flex align-items-center'>
          <div className='d-flex flex-column'>
            {coordinator.document_number}
          </div>
        </div>
      }
    </>
  )
}

export {CoordinatorDocumentNumberCell}
