// @ts-nocheck
import {Column} from 'react-table'
import {OperatorInfoCell} from './OperatorInfoCell'
import {OperatorStatusCell} from './OperatorStatusCell'
import {OperatorCustomHeader} from './OperatorCustomHeader'
import {Operator} from '../../core/_models'
import { OperatorDocumentNumberCell } from './OperatorDocumentNumberCell'
import { OperatorIdCell } from './OperatorIdCell'
import { OperatorActionsCell } from './OperatorActionsCell'
import { OperatorGenericCell } from './OperatorGenericCell'
import { OperatorDateCell } from './OperatorDateCell'
import {Menus, isAccessGranted} from '../../../../../../../app/helpers/MenuAccessControl'

const operatorsColumns: ReadonlyArray<Column<Operator>> = [
  {
    Header: (props) => (
      <OperatorCustomHeader tableProps={props} title='ID' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <OperatorIdCell operator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OperatorCustomHeader tableProps={props} title='Operador' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <OperatorInfoCell display={(isAccessGranted(Menus.operators.id).write) ? true : false} operator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OperatorCustomHeader tableProps={props} title='E-mail' className='min-w-90px' />
    ),
    id: 'email',
    Cell: ({...props}) => <OperatorGenericCell labelName={props.data[props.row.index].email} />,
  },
  {
    Header: (props) => (
      <OperatorCustomHeader tableProps={props} title='Documento' className='min-w-90px' />
    ),
    id: 'type',
    Cell: ({...props}) => <OperatorDocumentNumberCell operator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OperatorCustomHeader tableProps={props} title='Novo' className='min-w-90px' />
    ),
    id: 'is_new_user',
    Cell: ({...props}) => <OperatorGenericCell labelName={props.data[props.row.index].is_new_user ? 'Sim' : 'Não'} />,
  },
  {
    Header: (props) => (
      <OperatorCustomHeader tableProps={props} title='Status' className='min-w-20px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <OperatorStatusCell operator={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <OperatorCustomHeader tableProps={props} title='Criado Em' className='min-w-20px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <OperatorDateCell customDate={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <OperatorCustomHeader display={(isAccessGranted(Menus.operators.id).write) ? true : false} tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <OperatorActionsCell display={(isAccessGranted(Menus.operators.id).write) ? true : false} operator={props.data[props.row.index]}
      />
    ),
  },
]

export {operatorsColumns}
