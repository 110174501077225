import {useQuery} from 'react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getLogsBoxFilter} from '../core/_requests'
import {LogBoxStatus} from '../core/_models'
import {useAuth} from '../../../../auth'

const EditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {currentUser} = useAuth()

  const {
    isLoading,
    data: logBox,
    error,
    status
  } = useQuery(
    `${QUERIES.LOGS_BOX_LIST}-log-box-${itemIdForUpdate}`,
    () => {
      return getLogsBoxFilter({company_id: currentUser?.last_company_id, id: itemIdForUpdate, take: 0, skip: 1})
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <EditModalForm
        isLogBoxLoading={isLoading}
        response={{
          status_id: LogBoxStatus.active.id,
          created_at: new Date(),
        }}
        status={status}
      />
    )
  }

  if (!isLoading && !error && logBox) {
    return (
      <EditModalForm
      isLogBoxLoading={isLoading}
        response={logBox?.data}
        status={status}
      />
    )
  }

  return null
}

export {EditModalFormWrapper}
