import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {
  Manager,
  ManagersQueryResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getManagersFilter = (lastCompanyId: number | undefined, query: Record<string, any>): Promise<ManagersQueryResponse> => {
  return axios
    .get(`${API_URL}/managers/filter/${lastCompanyId}`, {params: query})
    .then((d: AxiosResponse<ManagersQueryResponse>) => d.data)
}

const createManager = (manager: Manager): Promise<Manager | undefined> => {
  return axios
    .post(`${API_URL}/managers`, manager)
    .then((response: AxiosResponse<Response<Manager>>) => response.data)
    .then((response: Response<Manager>) => response.data)
}

const updateManager = (manager: Manager): Promise<Manager | undefined> => {
  return axios
    .put(`${API_URL}/managers/${manager.id}`, manager)
    .then((response: AxiosResponse<Response<Manager>>) => response.data)
    .then((response: Response<Manager>) => response.data)
}

const getAllExportXls = (company_id: ID): Promise<Manager[]> => {
  return axios
    .get(`${API_URL}/managers/all/${company_id}/export`)
    .then((d: AxiosResponse<Manager[]>) => d.data)
}

export {
  getManagersFilter,
  createManager,
  updateManager,
  getAllExportXls
}
