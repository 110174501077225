import axios, {AxiosResponse} from 'axios'
import {AuthModel, UserModel, IGetUserByFilter, IPasswordRecovery} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/auth/users/filter`
export const LOGIN_URL = `${API_URL}/auth`
export const REGISTER_URL = `${API_URL}/register`
export const PASSWORD_RECOVERY_URL = `${API_URL}/users/password-recovery`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  name: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    name,
    password,
    password_confirmation,
  })
}

export function passwordRecovery(email: string): Promise<IPasswordRecovery> {
  return axios.post<IPasswordRecovery>(PASSWORD_RECOVERY_URL, {
    email,
  })
  .then((response: AxiosResponse) => response.data)
}

export function getUserByFilter(filter: IGetUserByFilter) {
  return axios.get<UserModel[]>(GET_USER_BY_ACCESSTOKEN_URL, {
    params: filter,
  })
}
