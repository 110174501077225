import {useQuery} from 'react-query'
import {CoordinatorEditModalForm} from './CoordinatorEditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getCoordinatorsFilter} from '../core/_requests'
import {CoordinatorStatus} from '../core/_models'
import {useAuth} from '../../../../auth/core/Auth'

const CoordinatorEditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)
  const {currentUser} = useAuth()

  const {
    isLoading,
    data: coordinator,
    error,
    status
  } = useQuery(
    `${QUERIES.COORDINATORS_LIST}-coordinator-${itemIdForUpdate}`,
    () => {
      return getCoordinatorsFilter(currentUser?.last_company_id, {id: itemIdForUpdate, take: 0, skip: 1})
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <CoordinatorEditModalForm
        isCoordinatorLoading={isLoading}
        response={{
          status_id: CoordinatorStatus.active.id,
          created_at: new Date(),
        }}
        status={status}
      />
    )
  }

  if (!isLoading && !error && coordinator) {
    return (
      <CoordinatorEditModalForm
      isCoordinatorLoading={isLoading}
        response={coordinator?.data}
        status={status}
      />
    )
  }

  return null
}

export {CoordinatorEditModalFormWrapper}
